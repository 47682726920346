ptz-tab-menu {
  width: 300px;
  box-sizing: border-box;
  align-items: center;
  display: inline-flex;
  padding: $petz-spacing-xs $petz-spacing-sm;
  height: $petz-spacing-2xl;
  color: $petz-color-neutral-dark;
  border: $petz-border-none;
  flex: none;
  font-weight: $petz-font-weight-bold;
  cursor: pointer;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 48px;
    border-radius: $petz-rounded-max;
    background: $petz-color-neutral-light;
  }

  &:hover {
    background-color: $petz-color-neutral-lighter-accent;
  }

  .ptz-tab-icon {
    padding-right: $petz-spacing-2xs;
  }

  ptz-icon {
    display: flex;
    color: $petz-color-neutral-dark;
  }

  .ptz-arrow-tab-icon {
    margin-left: auto;
  }

  &.ptz-tab-menu-disabled {
    background-color: $petz-color-neutral-lighter-accent;
    color: $petz-color-neutral-dark;
    cursor: not-allowed;
  }

  &.ptz-tab-menu-active {
    color: $petz-color-neutral-black;

    &:before {
      background: $petz-color-neutral-black;
    }

    ptz-icon {
      color: $petz-color-neutral-black;
    }
  }
 .ptz-tab-menu-icon-skeleton {
    margin-right: $petz-spacing-2xs;
  }

 .ptz-tab-menu-border-skeleton {
    margin-right: $petz-spacing-sm;
    position: absolute;
    top: 0;
    left: 0;
  }

 .ptz-tab-menu-arrow-icon-skeleton {
    position: absolute;
    top: 14px;
    right: 0;
  }
}
