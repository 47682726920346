import { Component, Host, Prop, h } from '@stencil/core/internal';
import { getSkeletonWidth, valueToLowerCaseForDataTestId } from '../../utils/utils';
import { PTZTabMenuTypes } from './types/ptz-tab-menu.types';

@Component({
  tag: 'ptz-tab-menu',
  styleUrl: 'ptz-tab-menu.scss',
  shadow: false,
})
export class PTZTabMenu {
  /** Estado do tab menu */
  @Prop() state: PTZTabMenuTypes.Args['state'];

  /** Nome do ícone do tab menu */
  @Prop() iconName: PTZTabMenuTypes.Args['iconName'];

  /** Nome da variante do ícone tab menu */
  @Prop() iconVariant: PTZTabMenuTypes.Args['iconVariant'];

  /** Label do tab menu */
  @Prop() label: PTZTabMenuTypes.Args['label'];

  /** Adiciona um arrow icon no canto direito */
  @Prop() arrowIcon: PTZTabMenuTypes.Args['arrowIcon'] = false;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  render() {
    const fixedWidth = 74;
    const intervals = [
      { max: 29, w: 228 },
      { max: 22, w: 169 },
      { max: 12, w: 98 },
      { max: 8, w: 74 },
    ];

    return (
      <Host class={`ptz-tab-menu-${this.state}`}>
        {this.skeleton ? (
          <ptz-skeleton class={'ptz-tab-menu-border-skeleton'} width={2} height={48}></ptz-skeleton>
        ) : (
          <span class={`ptz-tab-menu-border ptz-tab-menu-border-${this.state}`}></span>
        )}
        {this.iconName &&
          this.iconVariant &&
          (this.skeleton && !this.iconName.includes('undefined') ? (
            <ptz-skeleton class={'ptz-tab-menu-icon-skeleton'} width={24} height={24}></ptz-skeleton>
          ) : (
            <ptz-icon class={`ptz-tab-icon`} name={this.iconName} variant={this.iconVariant} size="md"></ptz-icon>
          ))}

        {this.label &&
          (this.skeleton ? (
            <ptz-skeleton class={'ptz-tab-menu-label-skeleton'} width={getSkeletonWidth(this.label.length, intervals, fixedWidth)} height={24}></ptz-skeleton>
          ) : (
            <label data-testid={`${valueToLowerCaseForDataTestId(this.label)}-label`}>{this.label}</label>
          ))}

        {this.arrowIcon &&
          (this.skeleton ? (
            <ptz-skeleton class={'ptz-tab-menu-arrow-icon-skeleton'} width={24} height={24}></ptz-skeleton>
          ) : (
            <ptz-icon class={`ptz-arrow-tab-icon`} name="angle-right-b-solid" variant="solid" size="md"></ptz-icon>
          ))}
      </Host>
    );
  }
}
